ion-content {
  --background: #16254f1a;
  --padding-top: 50px;
  @media (max-width: map-get($grid-breakpoints, md)) {
    --padding-top: 56px;
    --padding-start: 8px;
    --padding-end: 8px;
  }

  &.inner-page {
    @media (max-width: map-get($grid-breakpoints, md)) {
      --padding-start: 16px;
      --padding-end: 16px;
    }
  }
  &.login {
    --background: #16254f1a;
  }
}

.Table {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  justify-content: space-between;
}

.Table-row {
  display: flex;
  flex-flow: row nowrap;
}

.Table-row-item {
  display: flex;
  flex-grow: 1;
  padding: 5px 0.5em;
  word-break: break-word;
  display: inline-block;
}

.u-Flex-grow3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}

.main-content {
  position: relative;

  .action-button-wrapper {
    position: absolute;
    top: -120px;
    left: 0;
  }
}

.blank-list-icon {
  width: 112px;
  height: 112px;
}

.wrapper {
  width: 100%;
  height: 55px;
  display: block !important;
  position: absolute;
  top: 56px;
  right: 0;
  border-radius: 0 0 20px 20px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    border-radius: 0 0 0px 20px;
    height: 194px;
    background-color: var(--ion-color-primary);
  }
}
