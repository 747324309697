@import "../../../../theme/maps.scss";

ion-toolbar.modal {
  --background: white;

  ion-icon {
    color: var(--ion-color-primary);
  }
}

ion-toolbar.modal-item-preview {
  --background: black;
}

ion-toolbar.modal,
ion-toolbar.modal-item-preview {
  --border-width: 0 !important;
}

ion-modal.modal-wraper {
  --height: 723px;
  --border-radius: 30px;

  ion-content {
    --padding-bottom: 48px;
    --padding-start: 48px;
    --padding-end: 48px;

    h1 {
      margin-bottom: 10px !important;
      font-size: 30px;
    }
  }
}

ion-modal.modal-wraper-fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-modal.modal-narrow {
  --width: 423px;
}

ion-modal.modal-wraper-wide {
  --width: 750px;
}
ion-modal.small-high {
  --height: 350px;
}

ion-content.modal-page {
  --background: white;
  --padding-top: 0;
  --offset-bottom: -30px !important;

  h2 {
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: bold;

    // @media (max-width: map-get($grid-breakpoints, md)) {
    //   margin-bottom: 20px;
    // }
  }

  h1 {
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin: 0;
    }
  }

  h1,
  h2 {
    color: var(--ion-color-primary);
    margin-top: 0;

    @media (max-width: map-get($grid-breakpoints, md)) {
      color: black;
    }
  }
  ion-grid,
  ion-col {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0px;
  }
}

.inbox-modal {
  ion-icon {
    width: 24px;
    height: 24px;
  }
  h3 {
    line-height: unset;
  }
}

ion-content.modal-item-preview {
  --background: black;
  --offset-bottom: 0px !important;
}
