ion-item, ion-card{
    border-radius: 10px;
    box-shadow: 0px 0px 21px 0px rgba(173,182,217,0.37);
  
    &.input-item, &.item-select, &.item-datetime{
        border-radius: 10px 10px 0 0;
        box-shadow: none;
  
        .label-floating{
          // margin-top: -4px;
        }
  
        .input-clear-icon, ion-select::part(icon){
          top: -7px;
        }
  
        .input-clear-icon{
          display: none;
          width: 7px;
          height: 7px;
        }
  
      &::part(native){
        min-height:56px;
        height: 56px;
        border-color: #6D7278;
        border-width: 0px 0px 1px;
      }
  
      &.item-has-focus{
        &::part(native){
          border-color: #4878FD;
        }
  
        &.item-has-value .input-clear-icon{
          display: block;
        }
      }
  
      &.item-has-value::part(native){
        border-color: #4878FD;
      }
      &.item-invalid::part(native){
        border-color:red;
      }
    }
   
    &.item-has-value, &.item-has-focus, &.item-datetime{
      ion-label{
        font-size: 12px!important;
        line-height: 20px;
        }
 
      &.item-textarea{
        --highlight-background: none;
        --border-width: 0;
      }
    }
  
    &.item-interactive-disabled{
      opacity: 0.8;
      ion-input{
        opacity: unset!important;
      }
  
      ion-label{
        opacity: unset!important;
      }
      .native-input[disabled].sc-ion-input-md{
        opacity: unset!important;
      }
    }
  }


  ion-item.accordion-item, 
ion-item.toggle-item, 
ion-item.checkbox-item,  
ion-item.in-list, 
ion-item.item-datetime, 
ion-item.item-select{
  --background-color: unset;
  background-color: unset;
  box-shadow: unset;
}


ion-card{
    --padding-start: 16px;
      --padding-end: 16px;
      --padding-top: 16px;
      --padding-bottom: 16px;
      padding: 16px;
      margin: 0 0 16px;
  
  
      ion-card-header{
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding: 0;
        
      }
  
      ion-card-content, .card-content-ios, .card-content-md{
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        padding: 0;
        padding-inline-start: 0;
        padding-inline-end: 0;
      }
  
      ion-col{
        padding-inline-start: 0px;
      padding-inline-end: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      }
  
      ion-item{
        padding-left: unset;
        padding-right: unset;
        --padding-inline-start: 0;
        --inner-padding-start:0;
        --inner-padding-end:0;
        --padding-start:0;
      }
  }
  
  ion-card, ion-toolbar{
    ion-item.item{
      box-shadow: none;
    }
  }


  .item-preview{
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    background: black;
  
    ion-img::part(image) {
        border-radius: 0;  
        max-width: 100%;
        max-height: 100%;
        height: initial;
        width: initial;  
        margin: auto;   
    }
  
    ion-badge{
      position: absolute;
      right: 20px;
      bottom: 20px;
      padding: 10px 20px;
      border-radius: 50px;
    }
  }


  .item-highlight, .item-inner-highlight{
    left: 0px;
    right: 0px;
    bottom: -1px;
    position: absolute;
    background: #4878FD;
    z-index: 1;
}

ion-item.item-has-focus{
  --background: #EFF3FF;
}

.item.item-trns {
	border-color: rgba(0, 0, 0, 0);
	--background: rgba(0, 0, 0, 0);
  color: white; 
}