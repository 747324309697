ion-header {
  position: relative;
  z-index: 1;

  ion-toolbar{
    &.ios{
      padding-top:unset;
    }
  }
}

ion-toolbar{
    @media (max-width: map-get($grid-breakpoints, md)) {
    height: 56px;
    }
  
    &.hamburger-toolbar{
      height: 92px;
      padding: 15px 0px!important;
  
      ion-icon{
        font-size: 24px;
        position: relative;
        top: -18px;
        left: 6px;
      }
    }
  }
  