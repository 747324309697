// ion-grid{
//     padding: 0px;
//   }
  
  ion-col{
    padding-top: 0;
    padding-bottom: 0;
  
    @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    }
  }
  
  .ion-margin-right{
    margin-right: var(--padding)
  }
  
  .ion-margin-left{
    margin-left: var(--padding)
  }

  .fixed{
    position: absolute;
    bottom: 20px;
    right: 36px;
    left: 44px;
    margin: auto;
  }

  .image-wrapper-min-height{
    min-height: calc(78vh - 60px);
  
    @media (min-width: map-get($grid-breakpoints, md)) {
      min-height: calc(430px);
    }
  }


  .links-cell{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .cell-link{
      --background: none;
      --box-shadow: none;
      color: var(--links);
      text-decoration: underline;
  }
  