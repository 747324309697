@import "./maps.scss";

.card-container {
  padding: 15px 20px 20px;
  position: relative;

  @media (max-width: map-get($grid-breakpoints, md)) {
    color: var(--ion-color-primary);
    margin: 0;
    padding: 10px 10px 0;
  }

  &:not(:first-of-type) {
    margin-top: 30px;
  }

  .card-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 15px;
  }

  .card-item-divider {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
  }
}

.card-title {
  margin-top: unset;
  margin-bottom: unset;
  color: #16254f;
  padding-right: 0;
}

.summery-list {
  display: flex;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
  @media (max-width: map-get($grid-breakpoints, md)) {
    flex-wrap: wrap;
    justify-content: right;
  }

  .summery-list-item {
    text-align: center;
    width: 25%;
    flex-shrink: 0;
    margin: 15px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 2px 3px 3px #8080807a;
    color: black;
    font-weight: bold;
    // &:not(:last-of-type),
    &:not(:nth-of-type(4n)) {
      border-left: 1px solid #e0e0e0;
    }
    &:not(:nth-of-type(4n)):last-child {
      border-left: none;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
      margin-bottom: 0;
      flex-shrink: unset;
      &:not(:last-of-type) {
        border-left: 1px solid rgba(224, 224, 224, 1);
      }
    }
  }
}

.PrivateSwitchBase-root-1 {
  padding: 3px 7px;
}

// .MuiFormGroup-row {
//   justify-content: flex-end;
// }

.graph-container {
  padding: 10px 60px 10px;

  @media (max-width: map-get($grid-breakpoints, md)) {
    padding: 10px;
  }
}
