.title-select-list {
  display: flex;

  ion-radio {
    margin-left: 5px;
  }

  ion-item {
    --min-height: 35px;
    --inner-padding-end: 5px;
    --padding-start: 5px;
    margin-right: 10px;
  }
}
