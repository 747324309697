@import "./maps.scss";
@import "./text.scss";
@import "./popover.scss";
@import "./segment.scss";
@import "./splitPane.scss";
@import "./pagesBackgrounds.scss";
@import "./grid.scss";
@import "./textfields.scss";
@import "./toggle.scss";
@import "./buttons.scss";
@import "./toastify.scss";
@import "./header.scss";
@import "./graph.scss";
@import "./checkbox.scss";
@import "./content.scss";
@import "./datePicker.scss";
@import "./radio.scss";
@import "./itemAndCard.scss";
:root {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);
  --ion-tab-bar-background: var(--ion-color-primary);
  --ion-tab-bar-color: var(--ion-color-primary-contrast);
  --ion-tab-bar-color-selected: var(--ion-color-secondary-contrast);
}

:root * {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin: 20px;
    padding: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: gray;
  }
}

ion-grid {
  padding: 15px;
}

.MuiTableRow-head th,
.table-container table:first-child tr.MuiTableRow-head th:first-child {
  background-color: var(--ion-color-primary);
  color: white;
  font-weight: bold;
}
.MuiToolbar-root.MuiToolbar-regular {
  min-height: 40px;
}
