@import "../../../../theme/maps.scss";

.rdrDateRangePickerWrapper {
  flex-direction: column;
  direction: ltr;
  width: 100%;
}

.rdrDefinedRangesWrapper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #b9b6b6;
  border-right: 0 !important;

  @media (min-width: map-get($grid-breakpoints, md)) {
    order: 2;
    align-items: center;
    padding: 6px 0;
  }
}

.rdrStaticRangeLabel {
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding: 10px 5px;
  }
}

.rdrStaticRanges {
  justify-content: space-between;
  width: 100%;
}

.rdrMonth {
  width: 100%;
}

.rdrMonths,
.rdrMonthAndYearWrapper,
.rdrMonthAndYearPickers,
.rdrDateDisplay,
.rdrStaticRanges {
  flex-direction: row-reverse;
}

.rdrInputRanges {
  display: flex;
}

.rdrMonthAndYearWrapper {
  max-width: 320px;
  margin: auto;
  padding-top: 0 !important ;

  .rdrNextPrevButton {
    transform: rotate(180deg);
  }
}

.rdrDateDisplayWrapper {
  border-bottom: 1px solid #b9b6b6;
}

.rdrMonths.rdrMonthsHorizontal {
  border-top: 1px solid #b9b6b6;
}

// .rdrStartEdge,
// .rdrEndEdge,
// .rdrDayEndOfWeek .rdrInRange,
// .rdrDayStartOfWeek .rdrInRange {
//   transform: rotate(180deg);
// }

// .rdrMonth {
//   direction: rtl;
// }

.rdrMonthName {
  text-align: center;
}
