@import "../theme/maps.scss";

.date-range-picker-btn {
  margin: 0 10px 0 0;
  font-size: 14px;
  --border-radius: 16px;
  --padding-start: 10px;
}

.date-picker {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-basis: auto;
  padding: 0;
  width: 200px;

  .MuiInputBase-root {
    font-size: 20px;

    &:before {
      width: 143px;
    }

    &.MuiInput-underline {
      width: 145px;
    }

    .MuiButtonBase-root {
      height: auto;
      padding: 0;
    }

    .MuiInputAdornment-positionEnd {
      margin-left: 0px;
    }
  }

  &.header-picker {
    color: white;

    .MuiInputBase-root {
      color: white;

      &:before {
        border-bottom-color: white;
      }

      &.MuiInput-underline {
        &:hover:not(.Mui-disabled):before {
          border-bottom-color: var(--ion-color-primary);
        }
      }

      .MuiButtonBase-root {
        color: white;
      }
    }
  }

  &.date-picker-btn {
    width: 130px;
    .MuiInputBase-root {
      font-size: 14px;
      font-weight: bold;
      color: white;
      border-radius: 16px;
      background: #16254f;
      margin-right: 10px;
      text-decoration: none;
      padding-right: 10px;

      &:before {
        display: none;
      }

      &.MuiInput-underline {
        width: 130px;
      }

      .MuiInputBase-input {
        font-family: "Open Sans Hebrew";
      }
    }
    .MuiInputAdornment-positionEnd {
      margin-left: 10px;
      height: unset;

      .MuiIconButton-root {
        color: white;
      }
    }
  }
}
