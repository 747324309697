ion-toggle {
  --handle-background-checked: var(--toggle-checked);
  padding-right: 10px;
  padding-left: 0;
}

.toggle-token {
  border-radius: 20px;
  border: 1px solid var(--toggle-checked);
  display: inline-block;
  padding-left: 6px;
  margin: 4px;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --background-activated-opacity: transparent;
  --background-focused-opacity: transparent;
  --background-hover-opacity: transparent;
  --transition: transparent;
  --background: transparent;
  --border-color: unset;
  --padding-start: 0;
  --inner-border-width: 0;

  &.item-checkbox-checked {
    background: #16254f;
    border: 1px solid #16254f;

    ion-label {
      position: initial;
      p {
        color: white;
      }
    }
  }

  ion-checkbox {
    --background: none;
    --background-checked: none;
    --border-width: 0px;
    --checkmark-color: white;
  }

  ion-label {
    display: flex !important;
    align-items: center;
    position: relative;
    right: -10px;
  }
}

ion-item.toggle-token::part(native) {
  padding-inline-start: 5px;
  padding-inline-end: 5px;
  min-height: 35px;
}

ion-item.toggle-item {
  --inner-padding-end: 0;

  .toggle-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.custom-list-button-wraper {
  ion-item {
    --min-height: 82px;

    &:hover,
    &:focus {
      --background-activated-opacity: 0;
      --background-focused-opacity: 0;
      --background-hover-opacity: 0;
    }

    &.item-disabled {
      opacity: unset;
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    ion-item.activityItem {
      margin-right: 20px;
    }
    &.inner {
      ion-item.activityItem {
        --padding-start: 40px;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    ion-item.activityItem {
      margin: 0 12px 12px 0px;
    }
  }

  .page-icon {
    position: absolute;
    border-radius: 10px;
    z-index: 2;
    // top: 35%;
    top: 28%;
    right: 10px;
    width: 45px;
    height: 45px;
    display: flex;

    ion-icon {
      font-size: 24px;
      margin: auto;
    }

    &.statistics {
      ion-icon {
        font-size: 35px;
        align-self: flex-end;
        margin: 0 auto;
      }
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      right: 0;
      top: 25%;
      width: 34px;
      height: 34px;
      border-radius: 5px;

      ion-icon {
        font-size: 20px;
      }

      &.statistics {
        ion-icon {
          font-size: 28px;
          align-self: flex-end;
          margin: 0 auto;
        }
      }
    }
  }

  ion-label {
    // position: absolute;
    // right: 18px;
    margin-right: 18px;

    // @media (max-width: map-get($grid-breakpoints, md)) {
    //   top: 9px;
    //   right: 15px;
    // }
  }
}

.custom-list-button-wraper.inner {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    ion-item.activityItem {
      --padding-start: 35px;
      --padding-bottom: 15px;
      --padding-top: 10px;
    }
    .page-icon {
      top: 32%;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    ion-item.activityItem {
      margin-right: 0;

      ion-label {
        margin-right: 0;
        position: relative;
        top: unset;
        right: unset;
      }
    }
  }
}

.custom-title-button {
  margin: 18px 0 0;
  display: inline;

  &.home-section-btn {
    display: block;
  }

  ion-button {
    --background: var(--button-gradient);
    --padding-bottom: 5px;
    --padding-top: 5px;
    --padding-end: 21px;
    --padding-start: 21px;
    margin-top: 0;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 0;
    height: 34px;
    font-size: 14px;
    font-weight: bold;

    .text-wrap {
      max-width: 184px;
      width: unset;
    }
  }

  ion-icon {
    width: 24px;
    height: 24px;
  }
}
