// background colors

.page-icon{
  &.statistics{
    background: var(--statistic-button-background);
  }

  &.bussiness{
    background: var(--business-background);
  }

  &.review{
    background: var(--review-button-background);
  }

  &.inbox{
    background: var(--inbox-button-background);
  }

  &.settings{
    background:var(--settings-button-background);
  }

  &.help{
    background:var(--help-button-background);
  }
}

.custom-list-button-wraper:not(.inner) {
  &:hover{
    &:before {
      content: '';
      position: absolute;
      top: 11px;
      right: 13px;
      left: 11px;
      z-index: 1;
      margin-right: 18px;
      border-radius: 10px;
      height: 84px;
      display: block;
    }
 }
  @media (max-width: map-get($grid-breakpoints, md)) {
    &:hover{
      &:before{
        content: '';
        position: absolute;
        top: -1px;
        right: 32px;
        left: 4px;
        z-index: 1;
        margin-right: -16px;
        border-radius: 10px;
        height: 84px;
        display: block;
      }
    }
  }

  &.statistics:hover:before {
    background: var(--statistic-button-background);
  }

  &.bussiness:hover:before {
    background: var(--business-background);
  }

  &.review:hover:before {
    background: var(--review-button-background);
  }

  &.inbox:hover:before {
    background: var(--inbox-button-background);
  }

  &.settings:hover:before {
    background:var(--settings-button-background);
  }

  &.help:hover:before {
    border-color:var(--help-button-background);
  }
}
  .custom-list-button-wraper{
&.inner{
  ion-item.statistics {
    --background: var(--statistic-header-background);
  }

  ion-item.review {
    --background: var(--review-header-background);
  }

  ion-item.inbox {
    --background: var(--inbox-header-background);
  }
}
}