@import "../../../theme/maps.scss";

ul.summary-table {
  display: flex;
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0;
  flex-wrap: wrap;

  @media (max-width: map-get($grid-breakpoints, md)) {
    justify-content: space-around;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;

    @media (max-width: map-get($grid-breakpoints, md)) {
      max-width: 33.3%;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 5px;
      align-items: flex-start;
    }

    .info-data {
      margin-right: 10px;
      text-align: center;

      p:first-of-type {
        font-size: 20px;
        font-weight: bold;
        color: #16254f;

        @media (max-width: map-get($grid-breakpoints, md)) {
          font-size: 18px;
        }
      }
    }

    &:not(:last-of-type):after {
      content: "|";
      display: block;
      height: 50px;
      margin-right: 15px;
      width: 1px;
      font-size: 30px;
      margin-left: 15px;
      font-weight: lighter;
    }
  }

  &.calcTotal {
    li:not(:last-of-type):after {
      content: "+";

      @media (max-width: map-get($grid-breakpoints, md)) {
        content: "";
        display: none;
      }
    }
  }
}
