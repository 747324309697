ion-split-pane {
  --side-min-width: 200px;
}

.split-pane-visible > .split-pane-side {
  border: none !important;
}

.split-pane-visible > ion-menu {
  max-width: 200px !important;
}

.split-pane-side > ion-menu {
  max-width: 200px !important;
}

.split-pane-side {
  //   &::part(container){
  //     border-radius: 0px 0px 0px 18px;
  //   }
  ion-content {
    top: 0;
    background: var(--ion-color-primary);
    // --background: white;
    // --border-radius: 18px 0 0 0;
    --overflow: hidden;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      top: 56px;
    }

    &::part(scroll) {
      padding: 0;
      // border-radius: 18px 0 0 0;
    }

    ion-list {
      background: #0076ba;
      height: 100%;
      overflow: auto;
      padding-top: 23px;
      // border-radius: 18px 0 0 0;

      .menu-divider {
        width: 160px;
      }

      ion-item.side-menu-desktop-header {
        margin-bottom: 20px;
      }

      ion-item {
        width: 100%;
        font-size: 14px;
        box-shadow: unset;
        --background: #0076ba;
        color: white;
        &.active {
          --background: linear-gradient(
            90deg,
            rgba(0, 54, 85) 0%,
            rgba(252, 109, 69, 0.1) 100%
          );
          font-weight: bold;
          border-radius: 0;
        }
        ion-icon {
          margin-left: 11px;
        }
        &:hover {
          --background: white;
          border-radius: 0;
        }
      }
    }
  }
}

.side-menu-mobile {
  z-index: 9999;
}
