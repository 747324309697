ion-popover.select-popover{
    .popover-content{
       min-width: 320px;
       border-radius: 11px;
       font-size: 14px;
       max-height: 20%;
       margin: 10px 0px 0 -10px !important;
       @media (max-width: map-get($grid-breakpoints, md)) {
         width: calc(100% - 32px);
         min-width: unset;
         max-height: 20%;
         margin: 10px 0 !important;
         left: 16px !important;
       }
       ion-item{
         font-size: 14px;
         --border-width: 0;
         --inner-border-width: 0;
         direction: ltr;
         text-align: right;
         height: 40px;
         border-radius: 0;
   
         ion-label{
           margin-right:10px;
         }
       }
    } 
}

ion-popover.more-options-popover{
  .popover-content{
    margin:-15px 35px;
    border-radius: 8px;
    width: 200px;

    ion-button{
      --padding-end: 35px;
      --padding-start: 10px;
      color: black;

      ion-icon{
        margin-left:10px;
        width:24px;
        height: 24px;
      }

      &.inbox-read{
        font-weight: bold;
        color: var(--links);
      }
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      margin: -15px 20px;
    }
  }
  
  ion-backdrop {
    --backdrop-opacity: 0;
  }
}

ion-backdrop.backdrop-hide{
  --backdrop-opacity: 0;
}

.popover-container{
.popover-content {
  background: transparent;
  box-shadow: none;
  bottom: 11px !important;
  text-align: left;

  @media (max-width: map-get($grid-breakpoints, md)) {
    left: 11px !important;
    top: initial !important;
  }

  @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
    top: 60px !important;
    margin-left: -245px;
    }

  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
    top: 73px !important;
    left: 0px !important;
    }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    top: 73px !important;
    margin-left: -524px;
  }

  ion-button{
    --border-radius: 100%;
    width: 48px;
    height: 48px;
    margin-top: 20px;

    ion-icon{
      font-size: 24px;
      color: #FF4960;
    }

    &::part(native){
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  } 
  
}

.popover-viewport{
display: flex;
flex-direction: column;

ion-button{
  align-self: flex-end;
  margin-left: 15px;
}
}

.popover-item{
margin: 25px 0 0;
--background: none;
box-shadow: none;
align-self: flex-end;
cursor: pointer;
--background-hover-opacity: 0;

.popover-item-title{
  display: block;
  background-color: white;
  color:var(--ion-color-primary);
  padding: 2px 15px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  line-height: 20.2px;
}

  .icon-wraper{
  width:38px;
  height:38px;
  background: var(--button-gradient);
  border-radius: 50%;
  padding-top: 3px;
  margin-right: 14px;
  
  ion-icon{
    margin: 0 8px;
    width: 22px;
    
  }
}
}
}

ion-backdrop {
  --backdrop-opacity: 0.77;
  background-color: var(--ion-color-primary);
}


ion-popover.bottom-page-popover {
  @media (max-width: map-get($grid-breakpoints, md)) {
    --width: 100% !important;
  }
  .popover-wrapper {
    .popover-content {
      padding: var(--ion-padding, 16px);
      margin: auto;
      border-radius: 10px !important;
      transform-origin: center center !important;
      text-align: center;
      width: unset;

      @media (max-width: map-get($grid-breakpoints, md)) {
        transform-origin: center bottom !important;
        bottom: 0px !important;
        right: 0!important;
        left: 0!important;
        top: unset !important;
        border-radius: 10px 10px 0 0 !important;
        height: 175px;
      }

      ion-button {
        height: 31px;
        font-size: 12px;
        margin-top: 13px;
        --padding-start: 32px;
        --padding-end: 32px;
        font-weight: bold;
        --border-radius: 5px;
      }

      ion-button.ion-color-light {
        --ion-color-base: var(--popover-button) !important;
      }

      ion-button.ion-color-dark {
        --ion-color-base: var(--popover-button) !important;
      }
    }
  }
}

ion-loading.transparent {
  .loading-wrapper {
    display: none;
  }
  ion-backdrop {
    background-color: transparent;
  }
}