@import "./maps.scss";

ion-back-button {
  margin: 0 8px;
}

ion-button {
  --border-width: 1px;

  &.icon-button {
    --padding-end: 0;
    --padding-start: 0;
  }
}

ion-button.main-button {
  --background: var(--ion-color-primary);
  height: 60px;
  --border-radius: 0;
  font-size: map-get($font-sizes, lg);
  font-weight: 600;
  margin: 52px 0 0;
}

ion-button.action-button {
  height: 48px;
  --background: var(--button-gradient);
  --border-radius: 24px;
  font-size: map-get($font-sizes, xs);
  margin: 0;
  &::part(native) {
    padding-inline-start: 22px;
    padding-inline-end: 22px;
  }
}

ion-button.submit-button {
  --background: var(--button-background);
}

.button-pop {
  min-width: 85px;
  height: 48px;
  font-size: 12px;
  font-weight: bold;
  --border-radius: 50px;
  --background: var(--button-gradient);
  --padding-end: 22px;
  --padding-start: 14px;

  @media (max-width: map-get($grid-breakpoints, md)) {
    position: fixed;
    bottom: 14px;
    left: 15px;
    z-index: 2000;
    direction: ltr;
    --padding-end: 80px;
    --padding-start: 30px;

    &.scroll-up {
      bottom: 80px;
    }
  }

  ion-icon {
    font-size: 24px;
  }
}

ion-button.button-flex-rows {
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  height: 40px;
  justify-content: space-between;

  ion-icon {
    height: 27px;
    width: 24px;
  }

  p {
    font-size: 12px;
  }
}

ion-router-link.icon-link {
  span {
    display: inline-block;
    position: relative;
    bottom: 2px;
    right: 8px;
  }
}

ion-back-button {
  width: 10px;
  height: 10px;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
}
.fileButton::file-selector-button {
  background-color: var(--ion-color-primary);
  color: white;
  line-height: 36px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 10px;
  &:hover {
    opacity: 0.8;
  }
}
