@import "./maps.scss";

.input-alerts{
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    display: flex;
    padding-right: 18px;
    // border-top: 1px solid #6D7278;
  
    &.valid{
      border-top: 1px solid #4878FD; 
    }
  
    &.error{
      border-top: 1px solid red; 
    }
}
  
.input-counter{
  margin-right: auto;
}
.input-error{
  color: red!important;
  font-size: smaller;
}

ion-select, ion-datetime{
  padding-top: 5px;
  padding-bottom: 8px;
}

.textarea-clear-button{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

ion-input button.input-clear-icon {
  position: relative;
  top: -18px;
  left: 0;
}

ion-item.input-item::part(native){
  min-height: unset;
  height: unset;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

ion-textarea{
  max-height: 138px;
  // font-size: 1rem!important;
  overflow-x: hidden;
  --padding-top: unset!important; 
  // --padding-bottom: unset!important;
  --padding-end: 10px!important;
  direction: rtl;

  @media (max-width: map-get($grid-breakpoints, md)) {
    max-height: 210px;
  }

  textarea{
    overflow: auto;
    &.native-textarea.sc-ion-textarea-md{
      overflow: hidden;
      // font-size: 1rem!important;
    }
  }
}

// .MuiInputBase-root.MuiInput-underline{
//   font-family: inherit;
//   width: 234px;

//   &:before{
//     width: 155px;
//   }
// }

