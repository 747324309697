@import "../../../../theme/maps.scss";

.page-title-wraper {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //margin-left: 15px;
  // background-color: white;
  // width: 100%;

  @media (max-width: map-get($grid-breakpoints, md)) {
    h1 {
      color: var(--ion-color-primary);

      &.breadCrumbs-title {
        font-size: 14px;
        font-weight: lighter !important;
      }
    }
  }

  &.login {
    height: 110px;
    h1 {
      color: black !important;
      &:first-of-type {
        font-size: 14px;
        font-weight: normal !important;
        line-height: normal !important;
      }
    }
  }
}

ion-modal .page-title-wraper {
  height: unset;

  .inner-table-title {
    font-size: 20px;
  }
}

.title-wrapper {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;

  .button-pop,
  .button-flex-rows,
  .more-options-button {
    margin-right: auto;
  }

  .button-pop {
    order: 2;
  }

  .button-flex-rows {
    order: 4;
  }

  .more-options-button {
    order: 3;
    ion-icon {
      color: white !important;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    &.with-tip {
      h1 {
        font-size: 5.9vw;
      }
    }
  }
}
