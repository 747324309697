// .logo {
//   // position: absolute;
//   right: 35px;
//   width: 40px;
// }

.headding-logo {
  height: 60px;
  vertical-align: middle;
}
