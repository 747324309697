@import "../../../../theme/maps.scss";

.table-container {
  @media (min-width: map-get($grid-breakpoints, md)) {
    margin: 20px auto;
    width: 98%;
    min-width: 450px;
  }

  table:first-child {
    tr {
      td:first-child,
      th:first-child {
        background-color: #f5f5f5;
        position: sticky;
        right: 0;
        z-index: 999;
        max-width: 250px !important;
        min-width: 100px;
        word-break: break-word;
      }
      th:first-child {
        z-index: 9999;
      }
      &:nth-child(2n) {
        background: #dee6ea;

        td:first-child {
          background: #d4dade;
        }
      }
    }
  }

  .MuiIconButton-root {
    margin: auto;
    padding: 8px;
  }

  .MuiIconButton-root {
    @media (max-width: map-get($grid-breakpoints, md)) {
      padding: 7px;
    }
  }

  &.with-actions {
    table:first-child {
      tr {
        td:last-child,
        th:last-child {
          background-color: #f5f5f5;
          position: sticky;
          left: 0;
          z-index: 999;
          max-width: 90px !important;
          min-width: 50px;
          align-items: center;
        }
        th:last-child {
          z-index: 9999;
        }
        &:nth-child(2n) td:last-child {
          background: #d4dade;
        }
      }
    }
  }
  .MuiTableRow-root {
    font-size: 14px;
  }
  &.homeGrid {
    table thead th:first-child,
    table tbody tr:first-child td {
      width: 50px !important;
      min-width: 50px;
    }
  }
  &.campaignsGrid {
    table thead th:first-child,
    table tbody tr:first-child td,
    table thead th:nth-child(2),
    table tbody tr:nth-child(2) td {
      width: 50px !important;
      min-width: 60px;
    }
  }
}

.card-item-divider {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.MuiFormControl-root {
  width: 100%;
}

.MuiTableSortLabel-root {
  flex-direction: row !important;
}

.MTableToolbar-actions-74 div div span:nth-child(2) {
  direction: ltr;
  display: inline-block;
}

.MuiToolbar-gutters {
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}
.MuiTableCell-root.MuiTableCell-head,
.MuiTableCell-root.MuiTableCell-body {
  padding: 5px;
  line-height: 1;
}
.MuiTableCell-root {
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding: 7px 10px !important;
    line-height: 15px !important;
    font-size: 13px !important;
  }
}
