h1 {
  font-size: 1.4rem;
  color: black;
  font-weight: bold !important;
  margin-top: 0;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    font-size: 35px;
    line-height: 48px;
  }
}

h2 {
  font-size: 20px !important;
  line-height: 32.2px;
}

h3 {
  font-size: 16px !important;
  line-height: 32.2px;
}

.modal-subtitle {
  font-size: 16px !important;
  line-height: normal;
  margin: 10px 0 !important;
}

* {
  -webkit-user-select: text; /* Chrome all / Safari all */
  -moz-user-select: text; /* Firefox all */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Likely future */
}

p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.small-p {
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 20px;
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 12px;
  }
}

.xl-p {
  font-size: 0.8rem !important;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 18px !important;
  }
}

.large-p {
  font-size: 16px !important;
  line-height: 24px;
}

.small-title {
  font-size: 12px !important;
}

.md-title {
  font-size: 14px !important;
  line-height: 19.2px;
}

.large-title {
  font-size: 24px !important;
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 16px !important;
    font-weight: bold;
    line-height: initial;
    margin-top: 8px;
  }
}
.extra-large-title {
  font-size: 36px !important;
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 16px !important;
    font-weight: bold;
    line-height: initial;
  }
}

.bold-text {
  font-weight: bold !important;
}

.light-text {
  font-weight: normal;
}

.white-text {
  color: white;
}

.text-shadow {
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.46);
}

.underline {
  text-decoration: underline;
}

.text-align-left {
  text-align: left;
}

.text-wrap {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100%;
  display: inline-block;
}

.ltr {
  direction: ltr;
}

.link {
  color: var(--links);
  text-decoration: none;
  font-size: 14px;

  a {
    color: var(--links);
    text-decoration: none;
  }
}

.emph-text {
  color: var(--emph-blue);
}

.sc-ion-label-ios-s > p {
  color: black;
  display: inline-block;
}

.sc-ion-label-md-s p {
  color: black;
}
