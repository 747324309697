@import "../../theme/maps.scss";

.login-wrapper {
  max-width: 450px;
  height: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 3px 3px #8080807a;
}

.actions-wrapper {
  position: fixed;
  //width: 93%;
  max-width: 420px;
  right: 0;
  left: 0;
  text-align: center;
  margin: auto;

  @media (max-width: map-get($grid-breakpoints, md)) {
    bottom: 0;
  }

  ion-button.main-button {
    margin-top: 5px;
    margin-bottom: 16px;
  }

  ion-button.login-switch-btn {
    color: #ff5b44;
    font-weight: bold;
    font-size: 12px;

    span {
      text-decoration: underline;
      margin-right: 5px;
    }
  }
}
